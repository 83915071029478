<template>
    <default-template>
        <v-layout class="fill-height justify-center align-start background-content">
            <v-responsive width="100%" :min-height="$vuetify.breakpoint.mobile ? '83vh' : '50vh'" max-width="1200" class="py-0 py-md-8 overflow-visible">
                <v-card tile elevation="0">

                    <v-card-title class="headline">
                        <b v-if="-1 < $route.path.indexOf('/center/notifications')" class="py-4 pl-4">공지사항</b>
                        <b v-if="-1 < $route.path.indexOf('/center/faqs')" class="py-4 pl-4">자주묻는질문</b>
                        <b v-if="-1 < $route.path.indexOf('/board/question')" class="py-4 pl-4">문의하기</b>
                    </v-card-title>

                    <!-- <v-divider/> -->

                    <!-- <v-img src="/images/center/center-banner.png" /> -->

                    <v-divider class="mt-4"/>
                    <!-- <v-card-text>
                        <v-btn-toggle tile class="d-flex">
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/notifications" :color="-1 < $route.path.indexOf('/center/notifications') ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">공지사항</v-btn></v-flex>
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/faqs"          :color="-1 < $route.path.indexOf('/center/faqs')          ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">자주묻는질문</v-btn></v-flex>
                            <v-flex xs4 class="v-btn px-0"><v-btn to="/center/questions"     :color="-1 < $route.path.indexOf('/center/questions')     ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">1:1 상담문의</v-btn></v-flex>
                        </v-btn-toggle>
                    </v-card-text> -->
                </v-card>

                <slot />
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import { mapActions, mapState } from "vuex";
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate,
    },
    computed: {
        ...mapState(["siteInfo"]),
    }
}
</script>

<style scoped>
.v-btn-toggle>>>.v-btn {
    opacity: 1 !important;
}
</style>