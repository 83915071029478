var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    staticClass: "fill-height justify-center align-start background-content"
  }, [_c('v-responsive', {
    staticClass: "py-0 py-md-8 overflow-visible",
    attrs: {
      "width": "100%",
      "min-height": _vm.$vuetify.breakpoint.mobile ? '83vh' : '50vh',
      "max-width": "1200"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [-1 < _vm.$route.path.indexOf('/center/notifications') ? _c('b', {
    staticClass: "py-4 pl-4"
  }, [_vm._v("공지사항")]) : _vm._e(), -1 < _vm.$route.path.indexOf('/center/faqs') ? _c('b', {
    staticClass: "py-4 pl-4"
  }, [_vm._v("자주묻는질문")]) : _vm._e(), -1 < _vm.$route.path.indexOf('/board/question') ? _c('b', {
    staticClass: "py-4 pl-4"
  }, [_vm._v("문의하기")]) : _vm._e()]), _c('v-divider', {
    staticClass: "mt-4"
  })], 1), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }